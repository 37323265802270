<template>
  <el-input
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    size="mini"
    style="width: 80px"
    :placeholder="$t('MetricBoxTransactionNumberPlaceholder')"
  >
  </el-input>
</template>

<script>
export default {
  props: {
    value: { type: String },
  },
};
</script>

<style></style>
