import endDateMetric from "./endDateMetric.vue";
import dateRangeMetric from "./dateRangeMetric.vue";
import orderbyMetric from "./orderbyMetric.vue";
import orderIDMetric from "./orderIDMetric.vue";
import phoneMetric from "./phoneMetric.vue";
import providersMetric from "./providersMetric.vue";
import providerTypeMetric from "./providerTypeMetric.vue";
import receiptStatusMetric from "./receiptStatusMetric.vue";
import regionMetric from "./regionMetric.vue";
import startDateMetric from "./startDateMetric.vue";
import stateMetric from "./stateMetric.vue";
import storeNameID from "./storeNameID.vue";
import TransNumMetric from "./TransNumMetric.vue";
import inputMetric from "./inputMetric.vue";
import inputMetricNumbers from "./inputMetricNumbers.vue";
import typeMetric from "./typeMetric.vue";
import areaMetric from "./areaMetric.vue";
import periodMetric from "./periodMetric.vue";
import dynamicTypeMetric from "./dynamicTypeMetric.vue";
import stringMetric from "./stringMetric.vue";
import singleAreaMetric from "./singleAreaMetric";
import colorCodeMetric from "./colorCodeMetric.vue";
import dynamicDropdownMetric from "./dynamicDropdownMetric.vue";
import sortMetric from "./sortMetric.vue";
import tMetric from "./t-Metric.vue";
import dropdownMetric from "./dropdownMetric.vue";
import regionChildrenMetric from "./regionChildrenMetric.vue";
import localSearchMetric from "./localSearchMetric.vue";
import IndustryMetric from "./IndustryMetric";

export const transactionMetrics = {
  endDateMetric,
  orderbyMetric,
  orderIDMetric,
  phoneMetric,
  providersMetric,
  providerTypeMetric,
  receiptStatusMetric,
  regionMetric,
  startDateMetric,
  stateMetric,
  storeNameID,
  TransNumMetric,
  typeMetric,
  dynamicTypeMetric,
  stringMetric,
  sortMetric,
  tMetric,
  IndustryMetric,
};

export const notificationMetrics = {
  regionMetric,
  inputMetric,
  startDateMetric,
  areaMetric,
  providersMetric,
  periodMetric,
};

export const providerAreaMetrics = {
  regionMetric,
  singleAreaMetric,
  stringMetric,
};
export const receiptsMetrics = {
  endDateMetric,
  orderbyMetric,
  orderIDMetric,
  phoneMetric,
  providersMetric,
  providerTypeMetric,
  receiptStatusMetric,
  regionMetric,
  startDateMetric,
  stateMetric,
  storeNameID,
  TransNumMetric,
  typeMetric,
  areaMetric,
  colorCodeMetric,
  stringMetric,
  dynamicDropdownMetric,
};

export const portalUsersMetrics = {
  stringMetric,
  dynamicDropdownMetric,
};

export const salesAgentsMetrics = {
  areaMetric,
  regionMetric,
  stringMetric,
  regionChildrenMetric,
};

export const creditWalletsMetrics = {
  inputMetric,
  dropdownMetric,
  inputMetricNumbers,
};

export const offersMetrics = {
  stringMetric,
  dynamicDropdownMetric,
  startDateMetric,
  endDateMetric,
  localSearchMetric,
};
export const creditLeadsMetrics = {
  inputMetric,
  dropdownMetric,
  dateRangeMetric,
  areaMetric,
  IndustryMetric,
};

export const creditLegalsMetrics = {
  inputMetric,
  dropdownMetric,
};

export const targetsMetrics = {
  stringMetric,
  dynamicDropdownMetric,
  startDateMetric,
  endDateMetric,
  localSearchMetric,
  providerTypeMetric,
};
export const promocodesMetrics = {
  stringMetric,
  // dynamicDropdownMetric,
  dateRangeMetric,
  dynamicTypeMetric,
};
export const adsMetrics = {
  providerTypeMetric,
  areaMetric,
  regionMetric,
  stringMetric,
  startDateMetric,
  dynamicTypeMetric,
};
export const disProfileMetrics = {
  stringMetric,
  // dynamicDropdownMetric,
  dateRangeMetric,
  dynamicTypeMetric,
};
export const creditLimitsMetrics = {
  inputMetricNumbers,
  inputMetric,
  dynamicDropdownMetric,
  dateRangeMetric,
};
export const creditLimitTabMetrics = {
  inputMetricNumbers,
  inputMetric,
  dynamicDropdownMetric,
};
export const marketingRatingsMetrics = {
  inputMetricNumbers,
  dateRangeMetric,
};
export const cashbackComponentsMetrics = {
  dynamicDropdownMetric,
  stringMetric,
};
