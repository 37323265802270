<template>
  <el-select
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
    :value="value"
    size="mini"
    clearable
    :placeholder="placeholder"
  >
    <el-option
      v-for="item in meta.source"
      :key="item[meta.sourceKey]"
      :label="$t(item[meta.sourceLabel])"
      :value="item[meta.sourceKey]"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "Dropdown Metric",
  props: {
    value: { type: String },
    placeholder: { type: String },
    meta: {
      type: Object,
      default() {
        return { source: [], sourceKey: "", sourceLabel: "" };
      },
    },
  },
};
</script>

<style></style>
