<template>
  <!-- <metricbox v-if="filters.state != 'pending'"> -->
  <el-tooltip
    :content="$t('MetricBoxReceiptStausTooltipContent')"
    :disabled="state != 'pending'"
    placement="top"
    effect="dark"
  >
    <el-select
      :placeholder="$t('MetricBoxReceiptStatusPlaceholder')"
      clearable
      :value="value"
      size="mini"
      :disabled="state == 'pending'"
      filterable
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      :multiple="meta.multiple"
    >
      <el-option
        v-for="(item, key) in statusesLookup"
        :key="key"
        :label="item.status"
        :value="item.status"
      >
      </el-option>
    </el-select>
  </el-tooltip>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    value: { required: false },
    state: { type: String },
    meta: {
      type: Object,
      default() {
        return {
          multiple: false,
        };
      },
    },
  },

  data() {
    return {
      receiptStatus: [
        { label: "pending", value: "pending" },
        { label: "intransit", value: "intransit" },
        { label: "inprogress", value: "inprogress" },
        { label: "complete", value: "complete" },
        { label: "rejected", value: "rejected" },
        { label: "Cancelled", value: "cancelled" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    statusesLookup() {
      let returnedArray = [];
      if (this.lookups && this.lookups.receiptStatuses) {
        returnedArray = JSON.parse(
          JSON.stringify(this.lookups.receiptStatuses)
        );
      }
      return returnedArray;
    },
  },
};
</script>
<style></style>
