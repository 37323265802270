<template>
  <el-select
    :placeholder="$t('MetricBoxPeriodPlaceholder')"
    size="mini"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  >
    <el-option
      v-for="(item, key) in periodStatuses"
      :key="key"
      :label="item"
      :value="item"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    value: { type: String },
  },
  data() {
    return {
      periodStatuses: ["All", "week", "twoWeeks", "month"],
    };
  },
};
</script>

<style></style>
