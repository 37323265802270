<template>
  <div>
    <el-select
      filterable
      ref="dropdown"
      :value="value"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      :placeholder="meta.placeholder"
      clearable
    >
      <el-option
        v-for="item in areas"
        :key="item.key"
        :label="item.value"
        :value="item.key"
      />
    </el-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  ...mapGetters({
    lookups: "getAllLookups",
  }),
  props: {
    value: {
      default() {
        return "";
      },
    },
    meta: {
      type: Object,
      default() {
        return {
          placeholder: "",
        };
      },
    },
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    areas() {
      return this.lookups && this.lookups.areas ? this.lookups.areas : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
