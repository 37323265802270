<template>
  <el-select
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
    :value="value"
    size="mini"
    clearable
    :placeholder="placeholder"
    filterable
    :multiple="meta.multiple"
  >
    <el-option
      v-for="item in lookups.industries"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "IndustryMetric",
  props: {
    value: { type: Number },
    placeholder: { type: String },
    meta: {
      type: Object,
      default() {
        return {
          multiple: false,
        };
      },
    },
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
  },
  data() {
    return {};
  },
};
</script>

<style></style>
