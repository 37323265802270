<template>
  <el-input
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    size="mini"
    :placeholder="$t('MetricBoxStoreNameID')"
  />
</template>

<script>
export default {
  props: {
    value: { type: String },
  },
};
</script>

<style></style>
