<template>
  <dropdownSelectAll
    :source="regions"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  >
    <template v-slot:optionBody>
      <el-option
        v-for="item in regions"
        :key="item.key"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </template>
  </dropdownSelectAll>
</template>

<script>
import dropdownSelectAll from "../../dropdownSelectAll";
import { mapGetters } from "vuex";

export default {
  props: {
    value: { type: Array },
  },
  components: {
    dropdownSelectAll,
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    regions() {
      let returnedArray =
        this.lookups && this.lookups.regionsChildren
          ? this.lookups.regionsChildren
          : [];
      return returnedArray;
    },
  },
};
</script>

<style lang="scss" scoped></style>
