<template>
  <el-select
    :placeholder="$t('MetricBoxStatePlaceholder')"
    size="mini"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :multiple="meta.multiple"
  >
    <el-option
      v-for="(item, key) in statusesLookup"
      :key="key"
      :label="item.status"
      :value="item.status"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    value: { required: false },
    meta: {
      type: Object,
      default() {
        return {
          multiple: false,
        };
      },
    },
  },
  // data() {
  //   return {
  //     filterStatuses: [
  //       "All",
  //       "pending",
  //       "in progress",
  //       "complete",
  //       "unfulfilled",
  //       "pending_operation",
  //     ],
  //   };
  // },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    statusesLookup() {
      let returnedArray = [];
      if (this.lookups && this.lookups.transactionStatuses) {
        returnedArray = JSON.parse(
          JSON.stringify(this.lookups.transactionStatuses)
        );
      }
      return returnedArray;
    },
  },
};
</script>

<style></style>
