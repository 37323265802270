<template>
  <el-select
    :value="value"
    clearable
    size="mini"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :multiple="meta.multiple"
  >
    <el-option
      v-for="p in lookups.sections"
      :key="p.key"
      :label="p.key"
      :value="p.key"
    >
      <span style="float: left">{{ p.key }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{
        p.title
      }}</span>
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: { required: false },
    meta: {
      type: Object,
      default() {
        return {
          multiple: false,
        };
      },
    },
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
  },
};
</script>

<style></style>
