<template>
  <el-select
    :value="value"
    filterable
    class="u-right-margin--2x"
    size="mini"
    placeholder="Select Color"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  >
    <el-option
      v-for="(item, key) in colorCodes"
      :key="key"
      :label="item.label"
      :value="item.intervals"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  data() {
    return {
      colorCodes: [
        {
          color: "firebrick",
          label: this.$t("MetricBoxColorCodeRed"),
          intervals: [
            [0, 0.5],
            [2, 1000],
          ],
        },
        {
          color: "#FCCD13",
          label: this.$t("MetricBoxColorCodeYellow"),
          intervals: [
            [0.51, 0.9],
            [1.1, 1.99],
          ],
        },
      ],
    };
  },
  props: {
    value: { type: Array },
    // state: { type: Array },
  },
};
</script>

<style></style>
