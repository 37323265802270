<template>
  <el-select
    :value="value"
    clearable
    filterable
    :multiple="false"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  >
    <el-option
      v-for="p in sortOptions"
      :key="p.label"
      :label="p.label"
      :value="p.value"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "sortMetric",
  props: {
    value: {},
  },
  data() {
    return {
      sortOptions: [
        {
          label: this.$t("MetricBoxSortOptionCreatedAtAsc"),
          value: "asc(createdAt)",
        },
        {
          label: this.$t("MetricBoxSortOptionCreatedAtDesc"),
          value: "desc(createdAt)",
        },
        {
          label: this.$t("MetricBoxSortOptionUpdatedAtAsc"),
          value: "asc(updatedAt)",
        },
        {
          label: this.$t("MetricBoxSortOptionUpdatedAtDsc"),
          value: "desc(updatedAt)",
        },

        // {
        //   label: this.$t("MetricBoxSortOptionOrderNumAsc"),
        //   value: "asc(orderId)",
        // },
        // {
        //   label: this.$t("MetricBoxSortOptionOrderNumDsc"),
        //   value: "desc(orderId)",
        // },

        {
          label: this.$t("MetricBoxSortOptionAmountAsc"),
          value: "asc(amount)",
        },
        {
          label: this.$t("MetricBoxSortOptionAmountDsc"),
          value: "desc(amount)",
        },
        {
          label: this.$t("MetricBoxSortOptionItemsAsc"),
          value: "asc(itemsCount)",
        },
        {
          label: this.$t("MetricBoxSortOptionItemsDesc"),
          value: "desc(itemsCount)",
        },
        // {
        //   label: "Name (Descending)",
        //   value: "desc(name)",
        // },
        {
          label: this.$t("MetricBoxSortOptionCreateDateAsc"),
          value: "asc(receipts.createdAt)",
        },
        {
          label: this.$t("MetricBoxSortOptionCreateDateDesc"),
          value: "desc(receipts.createdAt)",
        },
      ],
    };
  },
};
</script>

<style></style>
