<template>
  <!-- <metricbox
    v-if="
      lookups && filters.state != 'pending' 
    "
  > -->
  <el-tooltip
    :content="$t('MetricBoxProviderToolTipContent')"
    :disabled="state != 'pending'"
    placement="top"
    effect="dark"
  >
    <el-select
      v-if="lookups"
      :value="value"
      filterable
      multiple
      :disabled="state == 'pending'"
      size="mini"
      :placeholder="$t('MetricBoxProvidersPlaceholder')"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    >
      <el-option
        v-for="(item, key) in lookups.distributers"
        :key="key"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </el-tooltip>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: { type: Array },
    state: { type: Array },
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
  },
};
</script>

<style></style>
