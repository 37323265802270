<template>
  <el-select
    :value="value"
    clearable
    filterable
    remote
    :multiple="meta.multiple"
    :remote-method="filterData"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :placeholder="meta.placeholder"
    :loading="loading"
  >
    <el-option
      v-for="p in filteredData"
      :key="p.key"
      :label="p.label"
      :value="p.key"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "localSearchMetric",
  props: {
    value: {},
    meta: {
      type: Object,
      default() {
        return {
          data: [],
          placeholder: this.$t("MetricBoxDynamicDropdownDefaultPlaceholder"),
          multiple: false,
          lookupKey: "unitsMain",
        };
      },
    },
  },
  data() {
    return {
      filteredData: [],
      loading: false,
    };
  },
  methods: {
    filterData(text) {
      try {
        this.loading = true;
        let finalArray = this.childLookup.filter((singleItem) => {
          if (text.length > 0) {
            return singleItem.label.toLowerCase().match(text.toLowerCase());
          }
          return false;
        });
        this.filteredData = finalArray;
        this.loading = false;
      } catch (error) {
        console.log("error", error);
        this.loading = false;
      }
    },
    componentMounted() {
      let propValue = this.value;
      if (propValue && typeof propValue == "number") {
        let dropdownArray = this.childLookup.filter((singleItem) => {
          return singleItem.key == propValue;
        });
        this.filteredData = dropdownArray;
      }
    },
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    childLookup() {
      let targetLookup =
        this.lookups && this.lookups[this.meta.lookupKey]
          ? this.lookups[this.meta.lookupKey]
          : [];
      let returnedArray = targetLookup.map((item) => {
        if (item.id && item.name) {
          return {
            key: item.id,
            label: item.name,
          };
        }
        return {
          key: "",
          label: "",
        };
      });
      return returnedArray;
    },
  },
  mounted() {
    this.componentMounted();
  },
};
</script>

<style></style>
