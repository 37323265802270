<template>
  <el-date-picker
    size="mini"
    class="picker"
    value-format="yyyy-M-d"
    :value="value"
    type="date"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :placeholder="$t('MetricBoxEndDatePlaceholder')"
    align="right"
  >
  </el-date-picker>
</template>

<script>
export default {
  props: {
    value: { type: String },
  },
};
</script>

<style></style>
