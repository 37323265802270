<template>
  <div style="width: 200px">
    <el-row>
      <el-col :span="11" :offset="1">
        <el-input
          @change="valueChanged"
          @input="valueChanged"
          :placeholder="$t('MetricBoxTMetricFrom')"
          v-model="intervalFrom"
          size="mini"
          clearable
          type="number"
          @keyup.native.enter="enterClicked"
        ></el-input>
      </el-col>
      <el-col :span="11" :offset="1">
        <el-input
          @change="valueChanged"
          @input="valueChanged"
          :placeholder="$t('MetricBoxTMetricTo')"
          v-model="intervalTo"
          size="mini"
          clearable
          @keyup.native.enter="enterClicked"
        ></el-input>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      intervalFrom: null,
      intervalTo: null,
    };
  },
  methods: {
    valueChanged() {
      this.$emit("input", { from: this.intervalFrom, to: this.intervalTo });
    },
    enterClicked() {
      this.$emit("enterClicked");
    },
  },
  props: {
    value: { type: Object },
  },
  mounted() {
    if (this.value) {
      if (this.value.from) this.intervalFrom = this.value.from;
      if (this.value.to) this.intervalTo = this.value.to;
    }
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        this.intervalFrom = null;
        this.intervalTo = null;
      }
    },
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
