<template>
  <el-input
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
    v-model="value"
    size="mini"
    clearable
    :placeholder="$t('MetricBoxPhonePlaceholder')"
  />
</template>

<script>
export default {
  props: {
    value: { type: String },
  },
};
</script>

<style></style>
