<template>
  <el-select
    :value="value"
    clearable
    filterable
    :multiple="meta.multiple"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :placeholder="meta.placeholder"
  >
    <el-option
      v-for="p in meta.data"
      :key="p.key"
      :label="p.label"
      :value="p.key"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "dynamicDropdownMetric",
  props: {
    value: {},
    meta: {
      type: Object,
      default() {
        return {
          data: [],
          placeholder: this.$t("MetricBoxDynamicDropdownDefaultPlaceholder"),
          multiple: false,
        };
      },
    },
  },
};
</script>

<style></style>
