<template>
  <el-input
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
    :placeholder="$t('MetricBoxOrderIDPlaceholder')"
    :value="value"
    size="mini"
    clearable
  ></el-input>
</template>
<script>
export default {
  props: {
    value: { type: String },
  },
};
</script>

<style></style>
