<template>
  <el-radio-group
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    size="mini"
  >
    <el-radio-button label="all">{{
      $t("MetricBoxOrderByAllOption")
    }}</el-radio-button>
    <el-radio-button label="amount">{{
      $t("MetricBoxOrderByAmountOption")
    }}</el-radio-button>
  </el-radio-group>
</template>

<script>
export default {
  props: {
    value: { type: String },
  },
};
</script>

<style></style>
