<template>
   <el-date-picker
   size="mini"
    value-format="yyyy-M-d"
      type="daterange"
      range-separator="To"
      start-placeholder="From Date"
      end-placeholder="To Date"
       :value="value"
      @input="$emit('input', $event)"
    @change="$emit('change', $event)"
      >
    </el-date-picker>
</template>


<script>
export default {
  props: {
    value: { type: Array },
  },
};
</script>

<style></style>
